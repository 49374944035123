const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTHENTICATION_STATUS":
      return {
        ...state,
        authenticationStatus: action.payload,
      };
    case "SET_UNO_ACCESS_TOKEN":
      return {
        ...state,
        unoAccessToken: action.payload,
      };
    case "SET_UNO_REFRESH_TOKEN":
      return {
        ...state,
        unoRefreshToken: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "FORCE_USER_RELOAD":
      return {
        ...state,
        forceUserReload: state.forceUserReload + 1,
      };
    case "SET_SUB_DOMAIN":
      return {
        ...state,
        subdomain: action.payload,
      };
    case "SET_PORTAL_CONFIG":
      return {
        ...state,
        portalConfig: action.payload,
      };
    case "SET_UNO_ADS":
      return {
        ...state,
        unoAds: action.payload,
      };
    case "SET_UNO_ADS_LOADED":
      return {
        ...state,
        unoAdsLoaded: action.payload,
      };
    case "SET_GLOBAL_NAVIGATION":
      return {
        ...state,
        globalNavigation: action.payload,
      };
    case "SET_STORAGE_LOADED":
      return {
        ...state,
        storageLoaded: action.payload,
      };
    case "SET_LIBRARY_CONTENT":
      return {
        ...state,
        libraryContent: action.payload,
      };
    case "SET_LIBRARY_CONTENT_LOADED":
      return {
        ...state,
        libraryContentLoaded: action.payload,
      };
    case "SET_LIBRARY_NEW":
      return {
        ...state,
        libraryNew: action.payload,
      };
    case "SET_LIBRARY_NEW_LOADED":
      return {
        ...state,
        libraryNewLoaded: action.payload,
      };
    case "SET_LIBRARY_POPULAR":
      return {
        ...state,
        libraryPopular: action.payload,
      };
    case "SET_LIBRARY_POPULAR_LOADED":
      return {
        ...state,
        libraryPopularLoaded: action.payload,
      };
    case "SET_LIBRARY_TRENDING":
      return {
        ...state,
        libraryTrending: action.payload,
      };
    case "SET_LIBRARY_TRENDING_LOADED":
      return {
        ...state,
        libraryTrendingLoaded: action.payload,
      };

    case "SET_DASHBOARD_ROOT_FOLDER_OVERLAYS":
      return {
        ...state,
        dashboardRootFolderOverlays: action.payload,
      };
    case "SET_DASHBOARD_FOLDER_OVERLAYS":
      return {
        ...state,
        dashboardFoldersOverlays: action.payload,
      };

    case "SET_DASHBOARD_ROOT_FOLDER_IMAGES":
      return {
        ...state,
        dashboardRootFolderImages: action.payload,
      };
    case "SET_DASHBOARD_FOLDER_IMAGES":
      return {
        ...state,
        dashboardFoldersImages: action.payload,
      };

    case "SET_DASHBOARD_CREATE_FOLDER":
      return {
        ...state,
        dashboardCreateFolder: action.payload,
      };
    case "SET_CURRENT_OVERLAY_DOWNLOAD_FOLDER":
      return {
        ...state,
        currentOverlayDownloadFolder: action.payload,
      };
    case "SET_CURRENT_IMAGE_DOWNLOAD_FOLDER":
      return {
        ...state,
        currentImageDownloadFolder: action.payload,
      };
    case "SET_IMAGE_DROPPED":
      return {
        ...state,
        imageDropped: action.payload,
      };

    case "DASHBOARD_OVERLAYS_FORCE_RELOAD":
      return {
        ...state,
        dashboardOverlaysForceReload: state.dashboardOverlaysForceReload + 1,
      };
    case "DASHBOARD_IMAGES_FORCE_RELOAD":
      return {
        ...state,
        dashboardImagesForceReload: state.dashboardImagesForceReload + 1,
      };
    case "DASHBOARD_INBOX_FORCE_RELOAD":
      return {
        ...state,
        dashboardInboxForceReload: state.dashboardInboxForceReload + 1,
      };

    case "SET_DASHBOARD_SELECTION":
      return {
        ...state,
        dashboardSelection: action.payload,
      };
    case "TOGGLE_DASHBOARD_SELECTION": {
      // check if the item is selected
      const index = state.dashboardSelection.indexOf(action.payload);

      // remove or add the array item
      if (index > -1) {
        let newSelection = [...state.dashboardSelection];
        newSelection.splice(index, 1);
        return {
          ...state,
          dashboardSelection: newSelection,
        };
      } else {
        return {
          ...state,
          dashboardSelection: [...state.dashboardSelection, action.payload],
        };
      }
    }
    case "ADD_DASHBOARD_SELECTION": {
      // check if the item is selected
      const index = state.dashboardSelection.indexOf(action.payload);
      if (index > -1) {
        return state;
      } else {
        return {
          ...state,
          dashboardSelection: [...state.dashboardSelection, action.payload],
        };
      }
    }
    case "REMOVE_DASHBOARD_SELECTION": {
      // check if the item is selected
      const index = state.dashboardSelection.indexOf(action.payload);
      if (index === -1) {
        return state;
      } else {
        // remove the item from the array
        let newSelection = [...state.dashboardSelection];
        newSelection.splice(index, 1);
        return {
          ...state,
          dashboardSelection: newSelection,
        };
      }
    }

    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };

    case "SET_MENU_SIZE":
      return {
        ...state,
        menuSize: action.payload,
      };
    case "SET_MENU_OVERLAY_SHOW":
      return {
        ...state,
        menuOverlayShow: action.payload,
      };
    case "SET_MENU_OVERLAY_FOLDERS_SHOW":
      return {
        ...state,
        menuOverlayFoldersShow: action.payload,
      };
    case "SET_MENU_IMAGE_SHOW":
      return {
        ...state,
        menuImageShow: action.payload,
      };
    case "SET_MENU_IMAGE_FOLDERS_SHOW":
      return {
        ...state,
        menuImageFoldersShow: action.payload,
      };

    case "SET_DASHBOARD_SORT":
      return {
        ...state,
        dashboardSort: action.payload,
      };
    case "SET_DASHBOARD_VIEW":
      return {
        ...state,
        dashboardView: action.payload,
      };
    case "SET_MY_LIBRARY_SORT":
      return {
        ...state,
        librarySort: action.payload,
      };
    default:
      console.log("Reducer: invalid action type", action.type);
      return state;
  }
};

export default Reducer;
